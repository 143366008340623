//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Page404',
  data: function data() {
    return {};
  },
  computed: {
    message: function message() {
      return '特朗普说这个页面你不能进......';
    }
  }
};